import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StandaloneContent from "../containers/standalone-pages";
import StandaloneBanner from '../components/banner/banner';


const AboutPage = (props: any) => {
    const {data} = props;
    return (
        <Layout do_footy={false}>
            <SEO
                title="About"
                description="About me"
                image={data.file ? data.file.publicURL : null}
            />
            <StandaloneBanner
                src={data.file}
            />
            <StandaloneContent
                greeting={'Thank you for visiting my site'}
                body={[
                    'I work as a technical physician and PhD candidate at the Amsterdam University Medical Center. My work focuses on creating tools for predicting antidepressant treatment response. These could support psychiatrists in treatment selection and speed up the search for effective treatment. My work ranges from developing novel machine methods.tsx, to running the <a href="https://onderzoekmetmensen.nl/en/trial/27632"> LEOPARD </a> observational trial, and to validate our research, to spinning of our research towards clinical application.',
                    'Programming has been a passion of mine as long as I can remember. I have been building websites for over 15 years. I used to build computer games in my spare time during high school, and during my studies I finally specialized in machine learning, which I now get to work on, on a daily basis. This site is as much as work of passion as a medium to convey information.',
                    'This website showcases and expands on my published work and side-projects. Additionally I might take the liberty to tell some stories on my other passions: sports and traveling. Any opinions expressed on this blog are solely my own and do not reflect policy of affiliated institutions in any way. If any of this caught your interest, please feel free to get in touch.'
                ]}
                socials={['resume', 'github', 'linkedin', 'orcid']}
                greet={true}
            />
        </Layout>
    );
};

export default AboutPage;

export const pageQuery = graphql`
query {
    file(
      sourceInstanceName: { eq: "assets" }
      base: { eq: "adventure.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;